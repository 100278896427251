<template>
  <div class="main">
    <header-t />
    <nav2 />
    <div class="cont">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item
          v-for="item in crumbs"
          :key="item.categoryId"
        ><a :href="item.urlParams">{{
              item.categoryName
            }}</a></el-breadcrumb-item>
      </el-breadcrumb>
      <el-row
        v-if="!isPurchased"
        class="class-item"
      >
        <el-col :span="10">
          <div class="img-wrap">
            <img
              :src="coursePic"
              alt=""
            />
          </div>
        </el-col>
        <el-col :span="14">
          <div class="class-info">
            <ul>
              <li class="title">{{ courseName }}</li>
              <li></li>
<!--              <li>课程编码：{{ courseCode }}</li>-->
              <li>培训时间： {{ beginningTime }} 至 {{ endingTime }}</li>
              <li>
                价格：<span class="moneny">¥{{ coursePrice }}</span>
              </li>
              <li>
                <i class="el-icon-user"><span>浏览数：{{ viewCnt }}</span></i>
                <i class="el-icon-s-custom"><span>报名人数：{{ signupCnt }}</span></i>
                <i
                  :class="
                      ifCollected
                        ? 'collecte el-icon-star-on'
                        : 'collecte el-icon-star-off'
                    "
                  @click="handleCollection"
                ><span>{{ ifCollected ? "已收藏" : "收藏" }}</span></i>
              </li>
            </ul>
            <div class="btn-wrap">
              <el-button
                v-if="!isFree"
                @click="handleBuy"
                :disabled="isBuging"
                type="primary"
              >立即购买</el-button>
              <el-button
                v-else
                @click="handleBuy"
                :disabled="isBuging"
                type="primary"
              >立即报名</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- 迁移开始 -->
      <el-row
        v-else
        class="class-item"
      >
        <el-col :span="24">
          <div class="es-section course-detail-section">
            <div class="course-detail-top">
              <span class="course-detail-heading">{{ courseName }}</span>
              <span class="course-detail-code">课程编号：{{ courseCode }}</span>
              <ul class="course-operation">
                <li>
                  <a
                    href="#"
                    class="btn btn-default btn-xs"
                    title="分享"
                    :aria-expanded="false"
                  >
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="btn btn-default btn-xs"
                  ></a>
                </li>
              </ul>
            </div>
            <section class="course-freelearn-section">
              <div class="row">
                <div class="col-md-3">
                  <div id="circle-box">
                    <div class="progress">
                      <el-progress
                        type="circle"
                        :percentage="ratio"
                        :width="145"
                        :stroke-width="10"
                        color="#43bc60"
                        :format="setItemText"
                      >
                      </el-progress>
                    </div>
                  </div>
                </div>
                <div class="col-md-7">
                  <ul class="course-learn-list">
                    <li>
                      <p class="mb15 color-gray">
                        <i
                          class="iconfont icon-yiwancheng"
                          id="es-icon"
                        ></i>已完成
                      </p>
                      <span class="text-16">
                        <span class="color-primary">{{
                            learningRateData.finishedPeriodCount
                          }}</span>
                        /
                        <span class="mrl">{{
                            learningRateData.periodCount
                          }}</span>任务
                      </span>
                    </li>
                    <li>
                      <p class="mb15 color-gray">
                        <i
                          class="iconfont icon-renwu1"
                          id="es-icon"
                        ></i>学习有效期
                      </p>
                      <span class="text-16">{{ endingTime }}</span>
                    </li>
                    <li>
                      <p class="mb15 color-gray">
                        <i
                          class="iconfont icon-renwu"
                          id="es-icon"
                        ></i>下一学习任务
                      </p>
                      <span class="text-16">{{
                          learningRateData.nextPeriodName
                        }}</span>
                    </li>
                  </ul>
                </div>
                <div
                  class="col-md-2 pt10"
                  style="display: flex; justify-content: space-around"
                >
                  <el-button
                    @click="
                        handleDetail(
                          learningRateData.nextPeriodId,
                          learningRateData.nextPeriodType,
                          learningRateData.learningRate
                        )
                      "
                    class="go-course"
                  >开始学习</el-button>
                  <el-button
                    v-if="
                        this.sessionCourseId !== null &&
                        Number(this.courseId) !== this.sessionCourseId
                      "
                    @click="handleCourses"
                    type="warning"
                    class="change-replace"
                  >
                    确认换课
                  </el-button>
                </div>
              </div>
            </section>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="cont mar-bottom">
      <el-row :gutter="20">
        <el-col :span="18">
          <el-card
            shadow="nerve"
            class="card"
          >
            <el-tabs
              v-model="activeName"
              @tab-click="handleClick"
            >
              <el-tab-pane
                label="介绍"
                name="first"
              >
                <template v-if="this.courseIntroduction !== null">
                  <div class="info">
                    <div
                      class="content"
                      v-html="courseIntroduction"
                    ></div>
                  </div>
                </template>
                <div
                  v-else
                  class="info"
                >暂无介绍</div>
              </el-tab-pane>
              <el-tab-pane
                label="目录"
                name="second"
              >
                <template v-if="periodRows.length > 0">
                  <ul
                    v-for="item in periodRows"
                    :key="item.periodId"
                    class="task-list"
                  >
                    <li
                      v-if="item.periodType === 0"
                      class="task-item1"
                    >
                      <div class="name">
                        <i
                          class="iconfont icon-wenzhangpailie-"
                          @click="handleClickIcon"
                        ></i>
                        <i
                          class="iconfont"
                          @click="handleClickIcon"
                          style="display: none"
                        >&#xe997;</i>
                        <i
                          class="iconfont"
                          @click="handleClickIcon"
                          style="display: none"
                        >&#xe999;</i>
                        <span>{{ item.periodName }}</span>
                      </div>
                    </li>
                    <li
                      v-else
                      class="task-item"
                      @click="
                          handleDetail(
                            item.periodId,
                            item.periodType,
                            item.learningRate
                          )
                        "
                    >
                      <div class="name">
                        <i
                          class="iconfont icon-weixuanzhongyuanquan-copy"
                          @click="handleClickIcon"
                        ></i>
                        <i
                          class="iconfont"
                          @click="handleClickIcon"
                          style="display: none"
                        >&#xe997;</i>
                        <i
                          class="iconfont"
                          @click="handleClickIcon"
                          style="display: none"
                        >&#xe999;</i>
                        <span>{{ item.periodName }}</span>
                      </div>
                    </li>
                  </ul>
                </template>
                <div
                  v-else
                  class="noData"
                >暂无数据！</div>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-col>
      </el-row>
      <LiveCode
        v-if="LiveCodeDialog.show"
        :live-code-dialog="LiveCodeDialog"
        @dialogClose="dialogClose"
      />
    </div>
    <Footer />
  </div>
</template>
  <script>
import HeaderT from "@/components/HeaderT";
import Nav2 from "@/components/Nav2";
import Footer from "@/components/Footer";
import { applyForExchange } from "@/api/myCenter";
import { getFrontStat } from "@/api/course";
import LiveCode from "./components/LiveCode.vue"; // 引入组件
import {
  getClassDetail,
  searchCollectStatus,
  changeCollectStatus,
  signUp,
  getCourseLive,
} from "@/api/product.js";
import { mapGetters } from "vuex";
export default {
  name: "ClassDetail",
  components: {
    HeaderT,
    Nav2,
    Footer,
    LiveCode,
  },
  data() {
    return {
      isBuging: false, // 下单loading 标记
      activeName: "first",
      ishandleCollection: "true",
      ifCollected: false, // 是否已收藏,
      visitCount: 0, // 游览用户数
      signUpCount: 0, // 报名用户数
      crumbs: null,
      isPurchased: false, // 是否购买课程
      periodRows: [], //课时列表
      courseId: "", //课程Id
      learningRateData: {},
      beginningTime: "", //学习有效期-开始
      endingTime: "", //学习有效期-截至
      ratio: "", // 学习完成百分比
      courseName: "", //课程名称
      courseCode: "", //课程编码
      coursePrice: 0, // 课程价格
      courseIntroduction: null, // 课程简介-富文本
      isFree: null, // 是否免费课程
      coursePic: "", //产品图片
      urlParams: "", //url跳转参数
      sessionCourseId: null, // session的课程id
      signupCnt: "", //报名人数
      viewCnt: "", //浏览次数
      LiveCodeDialog: {}, //直播弹窗二维码
    };
  },
  computed: {
    ...mapGetters(["userId", "token"]),
  },
  mounted() {
    console.log(this.userId);
    this.courseId = this.$route.query.id;
    console.log("courseId:", this.courseId);
    // this.getClassDetail(this.courseId);
    this.getCourseLive(this.courseId);
    // this.getCollectStatus();
    //获取session的课程id
    this.sessionCourseId = JSON.parse(sessionStorage.getItem("courseId"));
    console.log("sessionCourseId:", this.sessionCourseId);
    // 请求课程浏览量和课程人数
    this.getFrontStat();
  },

  methods: {
    // 那课程详细信息
    getClassDetail(ClassId) {
      console.log(ClassId);
      getClassDetail(ClassId).then((res) => {
        console.log(res);
        // if (res.status === 200) {
        if (res.data.code === 0) {
          this.crumbs = res.data.data.crumbs;
          this.courseName = res.data.data.courseName;
          this.courseCode = res.data.data.courseCode;
          this.isPurchased = res.data.data.isPurchased;
          this.periodRows = res.data.data.periodRows;
          this.ifCollected = res.data.data.isCollected;
          this.visitCount = res.data.data.visitCount;
          this.signUpCount = res.data.data.signUpCount;
          this.beginningTime = res.data.data.beginningTime;
          this.endingTime = res.data.data.endingTime;
          this.learningRateData = res.data.data.learningRateData;
          this.ratio = Math.ceil(
            res.data.data.learningRateData.finishedPeriodCount /
              res.data.data.learningRateData.periodCount
          ); // 取整不保留小数
          this.coursePrice = res.data.data.coursePrice.toFixed(2);
          this.courseIntroduction = res.data.data.courseIntroduction;
          this.isFree = res.data.data.isFree;
          this.coursePic = res.data.data.coursePic;
          console.log(this.ratio);

          // 处理面包屑
          this.crumbs.forEach((item, index) => {
            if (index === 0) {
              Object.assign(item, {
                urlParams: `/classroom?parent=${item.categoryId}`,
              });
            }
            if (index === 1) {
              Object.assign(item, {
                urlParams: `/classroom?parent=${item.parentId}&second=${item.categoryId}`,
              });
            }
            if (index === 2) {
              Object.assign(item, {
                urlParams: `/classroom?parent=${this.crumbs[0].categoryId}&second=${item.parentId}&three=${item.categoryId}`,
              });
            }
          });
          console.log(this.crumbs);
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000,
            onClose: () => {
              this.$router.go(-1);
            },
          });
        }
        // }
      });
    },

    // 直播详情
    getCourseLive(ClassId){
      console.log(ClassId)
      getCourseLive(ClassId).then((res) =>{
        console.log(res)
        if(res.data.code === 0){
          this.crumbs = res.data.data.crumbs
          this.beginningTime = res.data.data.beginningTime
          this.courseCode = res.data.data.courseCode
          this.courseIntroduction = res.data.data.courseIntroduction
          this.courseName = res.data.data.courseName
          this.coursePic = res.data.data.coursePic
          this.coursePrice = res.data.data.coursePrice.toFixed(2);
          this.endingTime = res.data.data.endingTime
          this.isCollected = res.data.data.isCollected
          this.isFree = res.data.data.isFree
          this.isPurchased = res.data.data.isPurchased
          this.learningRateData = res.data.data.learningRate
          this.needRealName = res.data.data.needRealName
          this.periodRows = res.data.data.periodRows
          this.signUpCount = res.data.data.signUpCount
          this.teacher = res.data.data.teacher
          this.visitCount = res.data.data.visitCount

          // 处理面包屑
          // this.crumbs.forEach((item, index )=>{
          //   if(index === 0){
          //     Object.assign(item,{
          //       urlParams:`/classroom?parent = ${item.categoryId}`,
          //     })
          //   }
          //   if (index === 1) {
          //     Object.assign(item, {
          //       urlParams: `/classroom?parent=${item.parentId}&second=${item.categoryId}`,
          //     });
          //   }
          //   if (index === 2) {
          //     Object.assign(item, {
          //       urlParams: `/classroom?parent=${this.crumbs[0].categoryId}&second=${item.parentId}&three=${item.categoryId}`,
          //     });
          //   }
          // })
          // console.log(this.crumbs);
        }else{
          this.$message({
            type:'error',
            message:res.data.message,
            duration:3000,
            onClose: ()=>{
              this.$router.go(-1);
            }
          })
        }
      })
    },
    //查询 状态
    getCollectStatus() {
      console.log(this.$route.query.id);
      const obj = {
        userId: this.userId,
        courseId: this.$route.query.id,
      };
      searchCollectStatus(obj).then((res) => {
        console.log(res);
        // if (res.status === 200) {
        if (res.data.code === 0) {
          console.log(res.data.data);
        }
        // }
      });
    },
    // 改变状态
    handleCollection() {
      console.log("aaa1");
      const obj = {
        userId: this.userId,
        courseId: this.$route.query.id,
        type: this.ifCollected ? 2 : 1, //操作种类1收藏课程2取消收藏
      };
      changeCollectStatus(obj).then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.code === 0) {
            console.log("laili");
            this.$message({
              type: "success",
              message: res.data.message,
              duration: 1000,
              onClose: () => {
                this.ifCollected = !this.ifCollected;
              },
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        }
        console.log(res);
      });
    },

    handleClick(tab, event) {
      console.log(tab, event);
    },
    //  立即报班
    handleBuy() {
      if (!this.userId) {
        let $this = this;
        this.$message({
          type: "error",
          message: "请先登录再进行购买！",
          duration: 1000,
          onClose() {
            $this.$router.push({
              path: "/login",
            });
          },
        });
      } else {
        if (this.isFree) {
          // 免费课程直接调接口
          const obj = {
            courseId: this.courseId,
          };
          signUp(obj).then((res) => {
            if (res.data.code === 0) {
              console.log(res);
              this.$message({
                type: "success",
                message: res.data.message,
                onClose: () => {
                  location.reload(); // 成功刷新页面
                },
              });
            } else {
              this.$message({
                type: "error",
                duration: 1000,
                message: res.data.data.message,
              });
            }
          });
        } else {
          //需要走购买流程
          this.$router.push({
            path: "/confirm/order",
            query: {
              id: this.courseId,
            },
          });
        }
      }
    },
    // 点击课时切换图标
    handleClickIcon() {},
    // 进度条文字
    setItemText() {
      let text = "学习进度";
      return text + "\n\n" + this.ratio + "%";
    },
    // 目录点击
    handleDetail() {
      this.LiveCodeDialog.show = true;
      this.LiveCodeDialog = {
        show: true,
        title: "课程直播",
      };
    },
    // 弹窗关闭回调
    dialogClose() {
      this.isBuging = false;
    },
    //  申请换课
    handleCourses() {
      const obj = {
        preCourseId: this.sessionCourseId, //换课前课程id
        applyCourseId: this.courseId, //期望更换的课程id
        applyReason: "", // 申请原因 可选
      };
      applyForExchange(obj).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.$message({
            type: "success",
            message: `换课${res.data.message}`,
            onClose: () => {
              sessionStorage.removeItem("courseId");
              this.sessionCourseId = JSON.parse(
                sessionStorage.getItem("courseId")
              );
              console.log("sessionCourseId:", this.sessionCourseId);
              // location.reload()
            },
          });
        }
      });
    },
    // 课程前端统计
    getFrontStat() {
      getFrontStat({ courseId: this.courseId }).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.signupCnt = res.data.data.signupCnt; //报名人数
          this.viewCnt = res.data.data.viewCnt; //浏览次数
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
  },
};
</script>
  
  <style rel="stylesheet/scss" lang="scss" scoped>
.main {
  height: 100%;
  background-color: #f5f5f5;
  .tab-item {
    p {
      display: flex;
      margin-top: 10px;
    }
  }
  .cont {
    margin: 16px auto 0;
    width: 1170px;
    .class-item {
      background-color: #fff;
      padding: 10px;
      margin-top: 20px;
      /* 迁移页面样式 */
      .course-detail-section {
        position: relative;
        ul {
          list-style: none;

          .course-operation {
            position: absolute;
            list-style-type: none;
            padding-left: 0;
            li {
              float: left;
              margin-right: 10px;
              .course-detail-section .course-operation li > .btn {
                font-size: 14px;
                color: #919191;
                .btn-default:hover {
                  background-color: #f5f5f5;
                }
                .btn {
                  display: inline-block;
                  margin-bottom: 0;
                  font-weight: 400;
                  text-align: center;
                  vertical-align: middle;
                  touch-action: manipulation;
                  cursor: pointer;
                  background-image: none;
                  border: 1px solid transparent;
                  white-space: nowrap;
                  user-select: none;
                }
              }
            }
          }
        }
        .course-detail-top {
          padding-right: 130px;
          .course-detail-heading {
            margin-top: 20px;
            vertical-align: top;
            padding-left: 20px;
            display: flex;
            color: #313131;
            font-size: 24px;
            word-wrap: break-word;
            word-break: break-all;
          }
          .course-detail-code {
            margin-top: 20px;
            vertical-align: top;
            padding-left: 20px;
            display: flex;
            color: #565050;
            font-size: 14px;
            word-wrap: break-word;
            word-break: break-all;
          }
          .tags {
            float: left;
          }
        }
      }
      .course-freelearn-section {
        background-color: #fafafa;
        margin: 60px 20px;
        padding: 35px 15px;
        height: 70px;
        #circle-box {
          top: -45px;
          left: 75px;
          position: relative;
          width: 160px;
          height: 160px;
          border-radius: 50%;
          background-color: #fff;
          box-shadow: 0 0 2px 2px rgb(235, 235, 235);
          #circle {
            position: absolute;
            z-index: 10;
            left: calc(15px / 2);
            top: calc(15px / 2);
            transform: rotate(-90deg);
          }
          .progress {
            position: absolute;
            left: 7.5px;
            top: 7.5px;
            width: 145px;
            height: 145px;
          }
        }

        /* 已完成 学习有效期  下一学习任务*/
        .course-learn-list {
          list-style: none;
          font-size: 13px !important;
        }
        .course-learn-list > li:first-child {
          width: 33.33%;
        }
        .course-learn-list > li {
          float: left;
          width: 33.33%;
          .mb15 {
            margin-bottom: 15px !important;
          }
          .color-gray {
            color: #919191;
            .iconfont {
              color: #409eff;
            }
          }
          #es-icon {
            line-height: 1;
            font-size: 16px;
            font-style: normal;
            margin-right: 15px;
          }
          .text-16 {
            font-size: 16px !important;
            margin-left: 20px;
            .color-primary {
              color: #43bc60 !important;
            }
            .mrl {
              margin-right: 20px !important;
            }
          }
          .link-dark {
            cursor: pointer;
            color: #616161 !important;
          }
          a {
            text-decoration: none;
          }
        }

        .row {
          margin-left: -10px;
          margin-right: -10px;
          .col-md-3 {
            float: left;
            width: 25%;
            position: relative;
            min-height: 1px;
            padding-left: 12px;
            padding-right: 12px;
          }
          .col-md-7 {
            float: left;
            width: 50%;
            position: relative;
            min-height: 1px;
            padding-left: 12px;
            padding-right: 12px;
          }
          .col-md-2 {
            float: left;
            width: 18%;
            position: relative;
            min-height: 1px;
            padding-left: 12px;
            padding-right: 12px;
            .btn.btn-lg {
              font-size: 14px;
              padding: 12px 35px;
              line-height: 1.3333333;
              border-radius: 4px;
            }

            .go-course {
              // cursor: pointer;
              background: #43bc60;
              border: 1px #43bc60 solid;
              color: #fff;
            }
            .go-course:hover {
              opacity: 0.8;
            }
            a {
              text-decoration: none;
            }
          }
          .pt10 {
            padding-top: 20px !important;
          }
        }
      }

      .es-section {
        background: #fff;
        margin-bottom: 20px;
        border-radius: 4px;
      }
      section {
        display: block;
      }
      /* 迁移结束 */
      .img-wrap {
        width: 470px;
        height: 270px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .class-info {
        position: relative;
        height: 270px;
        overflow: hidden;
        ul {
          margin-top: 20px;
          overflow: hidden;
          li {
            width: 100%;
            float: left;
            text-align: left;
            margin-bottom: 20px;
            i {
              margin-top: 25px;
              span {
                margin-right: 20px;
                margin-left: 10px;
              }
            }
            .collecte {
              cursor: pointer;
            }
            .moneny {
              color: #fd9271;
              font-size: 20px;
              font-weight: bold;
            }
          }
          .title {
            line-height: 25px;
            max-height: 52px;
            text-align: left;
            color: #444;
            font-size: 25px;
            overflow: hidden;
            margin-bottom: 20px;
          }
          .floatR {
            text-align: right;
          }
        }
        .btn-wrap {
          position: absolute;
          right: 10px;
          bottom: 10px;
          float: right;
        }
      }
    }
    .mar20 {
      margin-top: 20px;
    }
    .card {
      .info {
        text-align: center;
        img {
          border: 1px #f00 solid;
          width: 80% !important;
          margin: 0 auto;
          text-align: center;
          height: auto;
        }
        .content {
          // text-align: left;
          margin-top: 20px;
          color: #333;
          /deep/ p {
            text-align: left;
            margin: 10px 0;
            line-height: 1.5;
          }
          /deep/ img {
            width: 90% !important;
            height: auto;
          }
        }
      }
    }
  }
  /* 迁移页面样式 */
  .mar-bottom {
    margin-bottom: 20px;
    .el-col-18 {
      width: 100%;
    }
    .task-list {
      background-color: #f5f5f5 !important;
      height: 52px;
      line-height: 52px;
      margin-top: 10px;
      // cursor: pointer;
      .task-item,
      .task-item1 {
        width: 100%;
        position: relative;
        .name {
          float: left;
          width: 100%;
          margin-left: 20px;
          text-decoration: none;
          color: #616161;
          text-align: left;
        }
        .iconfont {
          color: #409eff;
          padding: 10px;
        }
      }
      .task-item:hover {
        cursor: pointer;
        .name {
          color: #b7090a;
        }
      }
      .task-item1 {
        .name {
          span {
            font-weight: bold;
          }
        }
      }
      .task-list-md {
        .task-item {
          margin-bottom: 10px;
          position: relative;
          // text-align: left;
          float: left;
          .name {
            float: left;
            margin-left: 20px;
            text-decoration: none;
            color: #616161;
            text-align: left;
            border: 1px #f00 solid;
          }
        }
      }
    }
    .noData {
      padding: 10px 0;
      text-align: center;
    }
    /* 迁移页面样式结束 */
  }
  /deep/ .el-progress__text {
    white-space: pre; //使百分号与所添加文字换行
  }
}
</style>